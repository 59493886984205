const { REACT_APP_ROOT_API, REACT_APP_CARRIERAPPSHUB_API_URL } = process.env

export const uiUrl =
    window.location.hostname === 'localhost' ? 'https://qa-pricemanagement.hvac-engineering.eu' : window.location.origin
console.log(window.location.hostname)
export const apiUrl =
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
        ? `http://localhost:8080`
        : `${window.location.origin}${REACT_APP_ROOT_API}`

export const carrierAppsHubApiUrl = REACT_APP_CARRIERAPPSHUB_API_URL

export const appConfig = {
    uiUrl,
}
export default appConfig
