import React, { useState, useEffect, useContext } from 'react'
import { MenuItem, FormControl, InputLabel, Select } from '@material-ui/core'
import './homePage.css'
import RooftopMenu from '../rooftopsComponent/menu/menu'
import Header from '../header/header'
import { NavLink, useLocation } from 'react-router-dom'
import Cube from '../../assets/images/cube.png'
import { UserContext } from '../../context/userContext'
import { GetUserProfileAndPermissions } from '@carrier-sce-sharedpackages/auth-module'
import { appConfig } from '../../environments/environments'

function HomePage() {
    const [UserName] = useState('')
    const { pathname } = useLocation()
    const displayRooftopMenu = pathname.includes('rooftop')
    //eslint-disable-next-line
    const [state, setState] = useContext(UserContext)

    useEffect(() => {
        let username = (value) => {
            setState((prev) => ({
                ...prev,
                UserName: `${value.firstName} ${value.lastName}`,
            }))
        }
        GetUserProfileAndPermissions(appConfig.apiUrl, appConfig.uiUrl, username)
        //eslint-disable-next-line
    }, [])
    return (
        <div className='header'>
            <Header UserName={UserName} />
            <div className='menu'>
                <label>
                    <img alt='' src={Cube} className='cube' />
                </label>
                <FormControl variant='filled' className='formcontrol'>
                    <InputLabel htmlFor='filled-age-native-simple'>Product line</InputLabel>
                    <Select>
                        <MenuItem component={NavLink} to='/rooftops' value='rooftops'>
                            Rooftops{' '}
                        </MenuItem>
                        <MenuItem component={NavLink} to='/chillers' value='chillers'>
                            Chillers{' '}
                        </MenuItem>
                        <MenuItem component={NavLink} to='/controls' value='controls'>
                            Controls{' '}
                        </MenuItem>
                        <MenuItem component={NavLink} to='/control-depracted' value='control'>
                            Control (supervision) - depracted {' '}
                        </MenuItem>
                    </Select>
                </FormControl>
                {displayRooftopMenu && <RooftopMenu />}
            </div>
        </div>
    )
}

export default HomePage
