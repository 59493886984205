import Constants from '../constants'
import { apiUrl } from '../../environments/environments'

function GetProducts(max, machineType, callback, urlPath = '/Products') {
    let Params = '?machineType=' + machineType;
    if (max) Params = Params + '&max=' + max;
    fetch(apiUrl + urlPath + Params, {
        method: 'GET',
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json.result)
        })
}

function GetProductVersions(ProductID, callback, urlPath = '/Products/') {
    fetch(apiUrl + urlPath + ProductID + '/Versions', {
        method: 'GET',
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json.result)
        })
}
function DeleteProduct(ProductID, callback, urlPath = '/Products/') {
    fetch(apiUrl + urlPath + ProductID, {
        method: 'DELETE',
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json.result)
        })
}

function GetProductsOptions(name) {
    return fetch(`${apiUrl}/Products/${name}/Prices`, {
        method: 'GET',
        headers: Constants.ApiHeader(),
    })
        .then((res) => {
            return res.json()
        })
        .then((res) => res.result)
        .catch((error) => {
            console.error('Error fetching product options:', error)
        })
}

function GetProductVarconds(name) {
    return fetch(`${apiUrl}/Products/${name}/Varconds`, {
        method: 'GET',
        headers: Constants.ApiHeader(),
    })
        .then((res) => {
            return res.json()
        })
        .then((res) => res.result)
        .catch((error) => {
            console.error('Error fetching product varconds:', error)
        })
}

function UpdateProducts(products) {
    return fetch(`${apiUrl}/Products`, {
        method: 'PUT',
        body: JSON.stringify(products),
        headers: Constants.ApiHeader(),
    })
        .then((res) => {
            return res.json()
        })
        .then((res) => res.result)
        .catch((error) => {
            console.error('Error saving the prices:', error)
        })
}

export { GetProducts, GetProductVersions, DeleteProduct, GetProductsOptions, GetProductVarconds, UpdateProducts }
