import React, { useState, useEffect } from 'react'
import { GetUserPermissions } from '../../assets/interfaces/uMInterface'
import CustomTableControl from '../controlComponent/customTableControl'
import { GetProducts } from '../../assets/interfaces/productInterface'

function ControlComponent() {
    const [IsUserAllowed, setIsUserAllowed] = useState(true)
    const [SelectedPrices, setSelectedPrices] = useState()

    useEffect(() => {
        GetUserPermissions((result) => {
            if (result) {
                setIsUserAllowed(result.appAccess)
            }
        })
    }, [])

    useEffect(() => {
        if (!SelectedPrices)
            GetProducts(
                null,
                null,
                (products) => {
                    setSelectedPrices({ Options: products })
                },
                '/control'
            )
    })

    if (!SelectedPrices) return <h1>Loading...</h1>
    return (
        <div className='AppContent'>
            {IsUserAllowed ? (
                <CustomTableControl
                    SelectedPrices={SelectedPrices}
                    onSelectedVersionUpdate={(version) => setSelectedPrices(version)}
                />
            ) : (
                <h1>You are not authorized to see this content</h1>
            )}
        </div>
    )
}

export default ControlComponent
