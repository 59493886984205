import React, { useEffect, useState } from 'react'
import '../common.css'
import MaterialTable from 'material-table'
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'
import { ApiService } from '@carrier-ecat/workflowui-globalfunctions'
import ImportButton from '../importButton/importButton'
import ExportButton from '../exportButton/exportButton'
import { filterDataByBrand } from '../utils/filters'
import TableIcons from '../utils/tableIcons'
import { apiUrl } from '../../../environments/environments'

function WheelsPage() {
    const [data, setData] = useState([])
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [brandFilter, setBrandFilter] = useState('All')
    const [loading, setLoading] = useState(true)

    // Get data from database
    useEffect(() => {
        ApiService(`${apiUrl}/rooftop/wheels/`).then(({ status, data }) => {
            if (status === 200 && data) {
                setData(data.result)
            }
            setLoading(false)
        })
    }, [])

    // filter font side on brand
    useEffect(() => {
        setDataToDisplay(filterDataByBrand(data, brandFilter))
    }, [brandFilter, data])

    const uploadStart = () => {
        setLoading(true)
    }

    const uploadSuccess = (data) => {
        setData(data)
        setLoading(false)
    }

    const uploadError = () => {
        setLoading(false)
        // @TODO display error message
    }

    return (
        <div className='page-container'>
            <div className='actions'>
                <div>
                    <FormControl variant='outlined' className='select'>
                        <InputLabel id='demo-simple-select-outlined-label'>Brand</InputLabel>
                        <Select
                            labelId='demo-simple-select-outlined-label'
                            id='demo-simple-select-outlined'
                            value={brandFilter}
                            onChange={(e) => setBrandFilter(e.target.value)}
                            label='Brand'>
                            <MenuItem value='All'>All brands</MenuItem>
                            <MenuItem value='Carrier'>Carrier</MenuItem>
                            <MenuItem value='Ciat'>Ciat</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <ImportButton
                        api='/rooftop/wheels/'
                        onSuccess={uploadSuccess}
                        onError={uploadError}
                        onStart={uploadStart}
                    />
                </div>
                <div>
                    <ExportButton data={data} fileName='Wheels_Prices.xlsx' sheetName='Wheels_Prices' />
                </div>
                <div>{loading && <span> LOADING .... </span>}</div>
            </div>
            <div className='table-container'>
                <MaterialTable
                    title='Wheels prices'
                    data={dataToDisplay}
                    icons={TableIcons}
                    columns={[
                        { title: 'Model', field: 'Code_Modele' },
                        { title: 'Assembly', field: 'Config' },
                        { title: 'Size', field: 'Size' },
                        { title: 'Material', field: 'Material' },
                        { title: 'Channel', field: 'Channel_Spacing' },
                        { title: 'Price', field: 'Price' },
                    ]}
                    options={{
                        filtering: true,
                        paging: true,
                        paginationType: 'stepped',
                        search: false,
                    }}
                />
            </div>
        </div>
    )
}

export default WheelsPage
