import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import appConfig from './environments/environments';
import {ReactAuthWrapper} from '@carrier-sce-sharedpackages/reactauthwrapper';
import {UserProvider} from './context/userContext'
ReactDOM.render(
    <UserProvider>
    <ReactAuthWrapper appConfig={appConfig} >
    <App />
    </ReactAuthWrapper> </UserProvider>,
    document.getElementById('root')
  );

